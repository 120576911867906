
// import './App.css';
// import './index.scss'

import { Route,Routes, BrowserRouter as Router} from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes";
import AppUserProvider from "./context/UserContext";
import AuthRoute from './routes/AuthRoute';
import { PrivateRoute } from './routes/privateRoute';
import WelcomeRoute from './routes/WelcomeRoute';
import AppRoute from './routes/AppRoute';
import { useContext, useEffect, useState } from 'react';
import {UserContext} from './context/UserContext';
import { APIEndpoint } from './config/apiendpoint_config';
import AppServices from './services/appServices';
import GetVerifiedScreen from './features/WelcomeStack/Caregiver/GetVerifyScreen';
import WalkthroughScreen from './features/WelcomeStack/WalkthroughScreen';


function App() {

  const _appServices = new AppServices()
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn, authToken, setAuthToken, 
    isFirstTimeUser, setloggedInUserDetails, isCompleteWalkthroug,
   setIsCompleteWalkthrough,setgenderList, setRelationShipList,setResources,
   setAssesmentsDetails,setAssesmmentQuestion,setMessageCount,setAssementCount, messageCount  } = useContext(UserContext);
  //  const { isLoggedIn, authToken, setAuthToken, 
  //   isFirstTimeUser, setloggedInUserDetails, isCompleteWalkthroug,
  //  setIsCompleteWalkthrough,setgenderList, setRelationShipList,setResources,
  //  setAssesmentsDetails,setAssesmmentQuestion } = useContext(UserContext);

 const getUserDetails = () => {
   _appServices.getData(APIEndpoint.getUserDeatils).then(res => {
    // console.log("user Details", res)
     if (res.status == 200 && res.success) {
       let _res = res.data[0];
       setloggedInUserDetails(_res);
       
       _appServices.getData(APIEndpoint.getwalkthrough).then(res1 => {
         if (res1.status == 200 && res1.success) { 
           let _res1 = res1.data[0];
           setIsCompleteWalkthrough(_res1.isWalkthroughPassed);
           setIsLoading(false);
         }
       },err=>{
         setIsLoading(false);
       });
     }
   }, async (err) => {
     console.log('getUserDetails err', err);
     setIsLoading(false);
   })
 }

 const getGenderList = () =>{
   _appServices.getData(APIEndpoint.gender).then((res) => {
     if (res.status === 200 && res.success) {
      //  setgenderList(res.data);
      setgenderList([
        { id: '1', label: 'Male' },
        { id: '2', label: 'Female' },
        { id: '3', label: 'Non Binary' },
        { id: '4', label: 'Prefer Not to Say' }
    ]);
    // console.log('res.data',res.data)
     }
   })
 }

 const getRelationShipList = () =>{
   _appServices.getData(APIEndpoint.relationship).then((res) => {
     if (res.status === 200 && res.success) {
       setRelationShipList(res.data);
      //  console.log('res.data relation',res.data)
     }
   })
 }

 const getResources = () =>{
   _appServices.getData(APIEndpoint.getResources).then((res) => {
     if (res.status === 200 && res.success) { 
       setResources(res.data);
     }
   })
 }

 const getAssismentDetails = () => {
   _appServices.getData(`${APIEndpoint.assessments}`).then((res:any) => {
     if (res.status == 200 && res.success) {
       let _res = res.data;   
       setAssesmentsDetails(_res); 
       if(_res['currentAssessments'].length > 0){
         _appServices.getData(`${APIEndpoint.assessments}/${_res['currentAssessments'][0].patientAssessmentsUid}`).then((res) => {
             if (res.status == 200 && res.success) {
               let assesmmentQuestion = res.data[0];
               if(assesmmentQuestion.answeredQuestionCount ==0){
                setAssementCount(1)
               }
               setAssesmmentQuestion(assesmmentQuestion);
             }
           },err=>{ 
             console.log('Something went wrong')
           })
       }
     }
   }, err => { 
     console.log('err', err);
   })
 };

 const getMessageList = () => {
  _appServices.getData(`${APIEndpoint.postMessages}/all?type=1`).then(res => {
    if (res.status === 200 && res.success) {
      const unreadCount = res.data.filter((item:any )=> (!item.isRead && item.message!='undefined')).length;
      console.log('unreadCount app',unreadCount,res.data)
      setMessageCount(unreadCount)
    }
  }).catch(err => {
    console.log('err', err);
  });

}

 useEffect(() => {
  if (authToken && authToken !='') {
    getUserDetails();
    getGenderList();
    getRelationShipList();
    getAssismentDetails();
    getResources();
    getMessageList();
  } else {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); 
  }
}, [authToken,setAuthToken])

  return (
    <div className="App">
      <Router>
        <Routes>
        {/* <Route path="/" element={<GetVerifiedScreen/>}/> */}

          <Route path="/*" element={<AuthRoute />}/>
          {/* <Route path='welcome/*'element={<PrivateRoute component={WelcomeRoute}/>} />
          <Route path='user/*'element={<PrivateRoute component={AppRoute}/>} /> */}
          <Route path="/walkthrough" element={<WalkthroughScreen />}/>
          {/* <Route path="/*" element={<h1>Page Not Found</h1>} /> */}
         {isLoggedIn && isFirstTimeUser &&  <Route path='welcome/*'element={<PrivateRoute component={WelcomeRoute}/>} />}
         {isLoggedIn && (isFirstTimeUser == false) &&  <Route path='user/*'element={<PrivateRoute component={AppRoute}/>} />}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
