import React, { useContext, useEffect, useState } from "react";
import AppServices from "../../../../services/appServices";
import { UserContext } from "../../../../context/UserContext";
import { APIEndpoint } from "../../../../config/apiendpoint_config";
import { useNavigate } from "react-router-dom";
import { PRIMARY_COLOR, TERTIARY_COLOR } from "../../../../colors";
import Swal from "sweetalert2"; 

const SwitchPatients = (props: any) => {
  const _appServices = new AppServices();
  const navigate = useNavigate();

  const { loggedInUserDetails, setSelectedSecondaryProfileUUID } =
    useContext(UserContext);
  const [secondaryProfileUUID, setSelectedProfile] = useState<any>([]);
  const [profileList, setProfileList] = useState([]);
  const [isRequestingProfile, setIsRequestingProfile] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    let selectedProfileUUID = localStorage.getItem("secondaryProfileUUID");
    setSelectedProfile(selectedProfileUUID ? selectedProfileUUID : "");
    setIsRequestingProfile(true);
    _appServices.getData(APIEndpoint.getRelationsProfileList).then(
      (res) => {
        if (res.status == 200 && res.success) {
          let _res = res.data;
          setProfileList(_res);
        }
        setIsRequestingProfile(false);
      },
      (err) => {
        setIsRequestingProfile(false);
        console.log("err", err);
      }
    );
  };

  const switchProfile = async (uuid: any, role: any) => {
    let selectedProfileUUID = localStorage.getItem("secondaryProfileUUID");
    if (uuid != selectedProfileUUID) {
      setIsRequesting(uuid);
      let postJson = {
        roleId: role,
        uuid: uuid,
      };
      _appServices.postData(APIEndpoint.switchProfile, postJson).then(
        async (res) => {
          if (res.status == 200 && res.success) {
            let _res = res.data[0];
            setSelectedSecondaryProfileUUID(uuid);
            localStorage.setItem("isSecondaryProfile", "true");
            localStorage.setItem("secondaryProfileUUID", uuid);
            localStorage.setItem("secondaryProfile", "true")
            localStorage.setItem(
              "secondaryProfileTokens",
              JSON.stringify(_res)
            );
            setIsRequesting(false);
            _appServices.logData({
              Severity: "Info",
              Activity: "User Switched a profile",
              ActivityStatus: "Success",
              ActivityResponse: "Data Submitted",
              Operation: "User Activity",
            });
            Swal.fire({
              icon: 'success',
              title: 'Profile switched successfully',
              showConfirmButton: false,
              timer: 1500
            });
            // if(route.name == 'initialSwitchProfile'){
            //   _appServices.showSnackbar('Patient profile switched successfully')
            // }
            // route.name == 'initialSwitchProfile' ?  navigation.navigate('BottomTab') : navigation.navigate('Home');
            if(props && props.onSwitchProfileClick){
              props.onSwitchProfileClick()
            }
            navigate('/user/home')

          }
        },
        (err) => {
          setIsRequesting(false);
          console.log("err", err);
        }
      );
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'This Profile is already selected',
      });
    }
  };
  console.log(profileList, "profilelist");

  return (
    <div>
      <div className="container web-view mb-5">
        <div className="profile-form mt-5 ">
          <div className="row">
            <h5 className="header mt-5">Patients</h5>
          </div>

          <div className="row">
            <p className="mt-4">
              Hello {loggedInUserDetails.firstName}, which patient profile you
              would like to view?
            </p>
          </div>

          <div className="row mt-5">
            <div className="mt-5">
              {profileList?.length > 0 ? (
                profileList.map((profile: any) => (
                  <div key={profile.uuid} className="caregiver-item">
                    <div className="form-label thick">
                      <u>
                        {profile?.firstName}{" "}
                        {profile?.middleName ? profile?.middleName : ""}{" "}
                        {profile?.lastName}
                      </u>
                    </div>
                    <div
                      className="form-label assess-helper-text thick"
                      style={{ cursor: "pointer" }}
                    >
                      <u
                        onClick={() =>
                          switchProfile(profile?.uuid, profile?.role)
                        }
                      >
                        Switch
                      </u>
                    </div>
                  </div>
                ))
              ) : profileList.length === 0 && isRequesting ? (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    {/* <h5 className='loader'></h5> */}
                    ...Loading
                  </div>
                </div>
              ) : (
                <div>
                  <h5>...No data available</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container mobile-view mb-5 p-5">
        <div className="mt-5">
        <div className="d-flex flex-row justify-content-flex-start align-items-center">
          <div className="row" onClick={() => navigate(-1)}>
          <i className="fa fa-angle-left" style={{fontSize:28, color:PRIMARY_COLOR}}></i>
          </div>

          <div className="row text-center">
            <p  className="thick" style={{marginBottom:0,marginLeft:50,fontSize:20,color:TERTIARY_COLOR}}>Patients</p>
          </div>
              </div>
          <div className="row">
            <p className="mobile-content mt-4">
              Hello {loggedInUserDetails?.firstName}, which patient profile you
              would like to view?
            </p>
          </div>

          <div className="row">
            <div className="mt-5">
              {profileList?.length > 0 ? (
                profileList.map((profile: any) => (
                  <div key={profile.uuid} className="caregiver-item">
                    <div className="form-label thick">
                      <u>
                        {profile?.firstName}{" "}
                        {profile?.middleName ? profile?.middleName : ""}{" "}
                        {profile?.lastName}
                      </u>
                    </div>
                    <div
                      className="form-label assess-helper-text thick"
                      style={{ cursor: "pointer" }}
                    >
                      <u
                        onClick={() =>
                          switchProfile(profile?.uuid, profile?.role)
                        }
                      >
                        Switch
                      </u>
                    </div>
                  </div>
                ))
              ) : profileList.length === 0 && isRequesting ? (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    {/* <h5 className='loader'></h5> */}
                    ...Loading
                  </div>
                </div>
              ) : (
                <div>
                  <h5>...No data available</h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchPatients;
