import React, { useContext, useRef, useState } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import IntroAnimations from "../../../assets/Animations/Eisai-ADL-App-01_opening.gif";
import logo from "../../../assets/images/App_logo.png";
import CustomInput from "../../../component/CustomInput";
import PrimaryButton from "../../../component/PrimaryButton";
import { APIEndpoint, envConfig } from "../../../config";
import { UserContext } from "../../../context/UserContext";
import { isValidEmail } from "../../../helper/helper";
import AppServices from "../../../services/appServices";
import ForgotPasswordModal from "../ForgotPassword/ForgotPassword";

interface LoginProps { }
const initialState = {
  emailError: "",
  passwordError: "",
};

const Login: React.FC<LoginProps> = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={envConfig.captchaKey}>
      <MyForm />
    </GoogleReCaptchaProvider>
  )
};

const MyForm = () => {
  const _appService = new AppServices();
  const { setSessionDetails } = useContext(UserContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errors, seterrors] = React.useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const forgotModal = useRef<any>();
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const hideModal = () => {
    setShowModal(!showModal);
  };
  const { executeRecaptcha } = useGoogleReCaptcha();
  const outSideClick = (e: any) => {
    if (e.target == forgotModal.current) {
      setShowModal(false);
    }
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    seterrors({
      ...errors,
      emailError: "",
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    seterrors({
      ...errors,
      passwordError: "",
    });
  };

  const validateForm = () => {
    const errors: any = {};
    const isEmailValid = isValidEmail(email);
    if (!isEmailValid) {
      errors.emailError = "Invalid Email Format.";
    }
    if (!email) {
      errors.emailError = "Email is required.";
    }
    if (!password) {
      errors.passwordError = "Password is required.";
    }
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length) {
      seterrors(errors);
      return;
    }
    setLoading(true);
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      setLoading(false);
      return;
    }
    try {
      const tokenNew = await executeRecaptcha('adliLogin'); 
      if (!tokenNew) {
        console.error('Failed to get reCAPTCHA token');
        setLoading(false);
        return;
      }
      else {
        let postJson = {
          email: email,
          password: password,
          token: tokenNew,
          type: 'web'
        };
        try {
          let res = await _appService.postData(APIEndpoint.login, postJson);
          if (res.status == 200 && res.success) {
            let _res = res.data[0];
            if (_res.twoFactorEnabled) {
              navigate("/otp", {
                state: { res: res, email: email, password: password },
              });
              setLoading(false);
            } else {

              let sessionObj = {
                email: email,
                authToken: _res?.tokens,
                isLoggedIn: true,
                isFirstTimeUser: _res.isFirstTimeLogin,
                userType: _res.userType,
                userTypeId: _res.userTypeId,
              };
              setSessionDetails(sessionObj);
              setTimeout(() => {
                if (_res.isFirstTimeLogin && (_res.userTypeId == 1)) {
                  navigate('/welcome')
                } else if (_res.isFirstTimeLogin && (_res.userTypeId == 2)) {
                  navigate('/welcome')
                } else {
                  navigate('/user/home')
                }
                setLoading(false);
              }, 2000);
            }
          } else {
            setLoading(false);
            let errMessage = _appService.getErrorMessage(res);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errMessage,
            });
          }
        } catch (error: any) {
          setLoading(false);
          let errMessage = _appService.getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errMessage,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Failed to get reCAPTCHA token');
    }



  };
  const setTokenFunc = (getToken: any) => {
    setToken(getToken);
  };
  return (
    <div>
      <div className="row">
        {" "}
      </div>
      <div className="row adli-container d-flex pt-3 align-items-center app-bg-color">
        <div
          className="col-12 col-md-6 order-md-1 order-2 d-flex flex-column"

        >
          <h2 className="loginTitle" style={{ padding: "0px 15px" }}>
            Patient/Caregiver Login
          </h2>
          <div style={{ padding: "0px 15px" }}>
            <div className="">
              <CustomInput
                placeholder="Email"
                type="email"
                value={email}
                onChange={handleUsernameChange}
                errorText={errors.emailError}
              />
              <CustomInput
                placeholder="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                errorText={errors.passwordError}
                icon={true}
              />
              <div style={{ width: "100%", padding: "10px" }}>
                <p className="loginDesc">
                  You must be enrolled by a health care provider to use ADLi. If
                  you are already registered, please log in. If you aren't
                  registered, please share this <span><a href={envConfig.webUrl} target="_black">link</a></span> with your
                  doctor to discuss if ADLi could be helpful to you. For more
                  information, please visit our <span><a href={envConfig.faq} target="_black">FAQs</a></span>.
                </p>
              </div>
            </div>
            <div className="button-container">
              <PrimaryButton
                handleSubmit={handleSubmit}
                text="Log In"
                loading={loading}
              />
              <a
                href="#"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Forgot Password ?
              </a>
              <img
                style={{
                  width: "100px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
                src={logo}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-6 order-md-2 order-1"
        // style={{ height: `${window.innerHeight - 227}px` }}
        >
          <img style={{ width: "100%" }} src={IntroAnimations} alt="Animation" />
        </div>
      </div>
      <div
        className={`forget-modal-container ${showModal ? "hide-forget-modal" : ""
          }`}
        ref={forgotModal}
        onClick={outSideClick}
      >
        <ForgotPasswordModal show={showModal} onHide={hideModal} />
      </div>
    </div>
  );

}
export default Login;
