import React, { useContext, useEffect, useState } from "react";
import MyProfile from "./MyProfile/MyProfile";
import ManageCaregivers from "./Caregivers/ManageCaregivers";
import ChangePassword from "./ChangePassword/ChangePassword";
import assessImg from "../../../assets/images/6A_Resources.png";
import bottomRightImg from "../../../assets/images/4_Assessment (Bush)_Web App.png";
import MfaVerification from "./Verification/MfaVerification";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../../component/Footer";
import { UserContext } from "../../../context/UserContext";
import SwitchPatients from "./Patients/SwitchPatients";
import leftBush from '../../../assets/images/bushLeft.png';
import rightBush from '../../../assets/images/bushRight.png';
import { envConfig } from "../../../config/env_config";
import Swal from "sweetalert2";
import AppServices from "../../../services/appServices";
import { PRIMARY_COLOR, SECONDRY_COLOR } from "../../../colors";

const MyAccounts = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const _appServices = new AppServices();
  const { userTypeId,loggedInUserDetails } = useContext(UserContext);
  const [isRequesting, setIsRequesting] = useState(false); 

  const [activeTab, setActiveTab] = useState<any>("current");

  useEffect(() => {
    if (localStorage.getItem("currentTab")) {
      setActiveTab(localStorage.getItem("currentTab"));
    } else {
      localStorage.setItem("currentTab", activeTab);
    }
  }, []);

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    localStorage.setItem("currentTab", tab);
  };
  const handlePrivacyPolicyClick = () => {
    window.open(envConfig.privacyUrl, "_blank");
  };
  
  const handleTermsConditionsClick = () => {
    window.open(envConfig.termsConditions, "_blank");
  };
  
  const handleFAQClick = () => {
    window.open(envConfig.faq, "_blank");
  };
  const handleSignOutPress = async () => { 
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Are you sure you want to log out?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, log out'
      });
      if (isConfirmed) {
        _appServices.signOut();
        setIsRequesting(true);
        await _appServices.logData({
          Severity: 'Info',
          Activity: `${loggedInUserDetails.email} has signed out`,
          ActivityStatus: 'Success',
          ActivityResponse: 'Data Submitted',
          Operation: 'User Activity',
        });
      }
    } catch (error) {
      console.error('Error while signing out:', error);
    }
  };

  return (
    <div style={{ marginTop: "80px" }}  className="position-relative">
      <div className="container1 p-5 web-view">
        {/* <div className="d-md-block d-none" style={{position:'fixed', bottom:0,left:0,zIndex:'-2'}}>
          <img src={leftBush} alt="leftbush" style={{height:'420px',width:'420px'}}/>
        </div>
        <div className="d-md-block d-none" style={{position:'fixed', bottom:0,right:0,zIndex:'-2'}}>
          <img src={rightBush} alt="rightbush" style={{height:'420px',width:'420px'}} />
        </div> */}

        <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, left: 0,zIndex:0 }}>
              <img src={leftBush} alt="" height={250} />
            </div>
        <div className="d-md-block d-none" style={{ position: 'absolute', bottom: 0, right: 0,zIndex:0 }}>
          <img src={rightBush} height={250} />
        </div> 
        
   
        {/* Tabs */}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 d-none d-sm-block">
            <div className="content d-block d-md-none">
              <i className="fa-solid fa-angle-left"></i>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 d-flex">
            <div className="my-profile" >
              <div
                className={`account-tab ${
                  userTypeId === 2 ? "has-5-buttons" : "has-4-buttons"
                }`}
              >
                <button
                  className={
                    activeTab === "current" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => handleTabClick("current")}
                >
                  My Profile
                </button>
                {userTypeId === 2 && (
                  <button
                    className={
                      activeTab === "switchPatient"
                        ? "tablinks active"
                        : "tablinks"
                    }
                    onClick={() => handleTabClick("switchPatient")}
                  >
                    Switch Patient
                  </button>
                )}
                <button
                  className={
                    activeTab === "manageCaregivers"
                      ? "tablinks active"
                      : "tablinks"
                  }
                  onClick={() => handleTabClick("manageCaregivers")}
                >
                  {userTypeId === 1 ? "Manage Caregivers" : "Manage Patients"}
                </button>
                <button
                  className={
                    activeTab === "changePassword"
                      ? "tablinks active"
                      : "tablinks"
                  }
                  onClick={() => handleTabClick("changePassword")}
                >
                  Change Password
                </button>
                <button
                  className={
                    activeTab === "twoStepVerification"
                      ? "tablinks active"
                      : "tablinks"
                  }
                  onClick={() => handleTabClick("twoStepVerification")}
                >
                  2-Step Verification
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Content for each tab */}
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <img src={assessImg} className="fit-img1" alt="Assess Image" />
          </div>
          <div className="col-lg-6">
            <div className={activeTab === "current" ? "current" : "previous"}>
              {activeTab === "current" && <MyProfile />}
              {activeTab === "switchPatient" && <SwitchPatients />}
              {activeTab === "manageCaregivers" && <ManageCaregivers />}
              {activeTab === "changePassword" && <ChangePassword />}
              {activeTab === "twoStepVerification" && <MfaVerification />}
            </div>
          </div>
        </div>
      </div>

      <div className="container mobile-view p-5">
        <div className="row">
          <h1 className="header">My Account</h1>
        </div>

        <div className="mt-5 thick">
          <div className="row">
            <div
              className="d-flex between sub-title"
              onClick={() => navigate("/user/mobile-profile")}
            >
              <div>
                <p style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Profile</p>
              </div>
              <div>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>

          {userTypeId == 2 && (
            <div className="row">
              <div
                className="d-flex between sub-title"
                onClick={() => navigate("/user/switch")}
              >
                <div>
                  <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Switch Patient</p>
                </div>
                <div>
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div
              className="d-flex between sub-title"
              onClick={() => navigate("/user/mobile-caregivers")}
            >
              {userTypeId === 2 ? 
              <div>
                <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Manage Patients</p>
              </div> :
               <div>
               <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Manage Caregivers</p>
             </div>
}
              <div>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="d-flex between sub-title"
              onClick={() => navigate("/user/mobile-change-password")}
            >
              <div>
                <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Change Password</p>
              </div>
              <div>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="d-flex between sub-title"
              onClick={() => navigate("/user/getVerified")}
            >
              <div>
                <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>2-Step Verification</p>
              </div>
              <div>
                <i className="fa fa-angle-right"></i>
              </div>
            </div>
          </div>
          <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={handlePrivacyPolicyClick}
          >
            <div>
              <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Privacy Policy</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={handleTermsConditionsClick}
          >
            <div>
              <p  style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Terms & Conditions</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={handleFAQClick}
          >
            <div>
              <p style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>FAQs</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            // onClick={() => navigate("/user/mobile-profile")}
          >
            <div>
              <p style={{color:SECONDRY_COLOR, fontSize:20,fontWeight:500 }}>Support</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        </div>

        <div className="row mt-5">
          <button className="button-primary" onClick={handleSignOutPress}>Sign Out</button>
        </div>
      </div>
    </div>
  );
};

export default MyAccounts;
