import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { envConfig } from "../../../config/env_config";

const MobileMyAccount = () => {
  const [activeTab, setActiveTab] = useState("current");

  const navigate = useNavigate();

  return (
    <div className="container mobile-view p-5">
      <div className="row">
        <h1 className="header">My Account</h1>
      </div>

      <div className="mt-5 thick" style={{ cursor: "pointer" }}>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={() => navigate("/user/mobile-profile")}
          >
            <div>
              <p>Profile</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={() => navigate("/user/mobile-caregivers")}
          >
            <div>
              <p>Manage Connections</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={() => navigate("/user/mobile-change-password")}
          >
            <div>
              <p>Change Password</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="d-flex between sub-title"
            onClick={() => navigate("/user/getVerified")}
          >
            <div>
              <p>2-Step Verification</p>
            </div>
            <div>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </div>

      </div>

      <div className="row mt-5">
        <button className="button-primary">Sign Out</button>
      </div>
    </div>
  );
};

export default MobileMyAccount;
